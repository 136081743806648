import styled from "styled-components";

interface IpropsContainerBody {
  isOpen?: boolean;
}
interface IpropSContainerHeader {
  hasActions?: boolean;
}
interface IpropsNameCell {
  margin?: boolean;
}
export const Container = styled.div`
  width: 100%;
  height: auto;

  input {
    width: 80%;
    height: 80%;
    box-shadow: 0 0 0 0;
    outline: 0;
    border-radius: 3px;
    border: 1px solid rgba(132, 149, 209, 0.4);
    background: rgba(132, 149, 209, 0.1);
    padding: 5px;
    font-size: 16px;
    text-align: center;
  }
`;

export const ContainerHeader = styled.div<IpropSContainerHeader>`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  cursor: ${(props) => (props.hasActions ? "pointer" : "auto")};
`;

export const BoxLines = styled.div`
  width: 100%;
  height: 100%;
`;

export const NameCell = styled.div<IpropsNameCell>`
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.margin ? "0 15px" : "0")};
  border-bottom: ${(props) =>
    props.margin ? " 1px solid rgba(0,0,0,0.3)" : "0"};

  p {
    color: black;
    margin-right: 10px;
    font-weight: 700;
  }
`;
export const NameCellWhitOut = styled.div<IpropsNameCell>`
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.margin ? "0 15px" : "0")};
  border-bottom: ${(props) =>
    props.margin ? " 1px solid rgba(0,0,0,0.3)" : "0"};

  p {
    color: black;
    margin-right: 10px;
    font-weight: 700;
  }
`;
export const Line = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
`;

export const ContainerBody = styled.div<IpropsContainerBody>`
  height: auto;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  background-color: #f3f4fa;
  flex-direction: column;
`;
export const LineCells = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
`;

export const Cell = styled.div`
  width: 20%;
  height: 100%;
  /* background-color: aliceblue; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  width: 80%;
  height: 80%;
  box-shadow: 0 0 0 0;
  outline: 0;
  border-radius: 3px;
  border: 1px solid rgba(132, 149, 209, 0.4);
  background: rgba(132, 149, 209, 0.1);
  padding: 5px;
  font-size: 16px;
  text-align: center;
`;

export const DivT = styled.div`
  display: flex;
  width: 11%;
  align-items: center;
  justify-content: flex-start;
`;

export const DivSumAll = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
