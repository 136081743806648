import styled from "styled-components";

export const Container = styled.main`
  min-height: 100vh;
  background-color: #F7F6F6;
  padding:5%;
`;


export const Title = styled.h1`
  margin-bottom: 2%;
`

export const ContainerFiltros = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
`