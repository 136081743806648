import React, { useEffect, useMemo } from "react";
import {
  BoxDates,
  BoxLines,
  CellDate,
  Container,
  HeaderDates,
  HorasTotais,
  Teste,
} from "./styles";
import Acordeon from "../accordeon";
import AccordeonSimple from "../accordeonSimple";
import { useSheet } from "@/hooks/useSheet";
import { decimalParaHoraMinuto } from "@/utils/date";
import { useTimes } from "@/hooks/useTime";

export default function Table() {
  const { currentsWeek } = useSheet();
  const { handleGetSheetUser, sheetData, totals, totalHoras } = useTimes();

  useEffect(() => {
    handleGetSheetUser();
  }, []);

  const somaHorasTotais = useMemo(() => {
    return totals.reduce((acc: any, total: any) => acc + total?.total || 0, 0);
  }, [totals]);

  return (
    <Container>
      <HeaderDates>
        <Teste />
        <BoxDates>
          {currentsWeek?.map((week: any, index: any) => (
            <CellDate key={index}>
              <p>{index + 1} Semana</p>
              <p>
                {week.at(0).dia}/{week.at(0).mes} a {week.at(-1).dia}/
                {week.at(0).mes}
              </p>
            </CellDate>
          ))}
        </BoxDates>
      </HeaderDates>
      <BoxLines>
        {sheetData?.map((category: any, index: any) => (
          <AccordeonSimple
            key={index}
            title={category.type?.name}
            arrow={category.projects.length > 0}
          >
            {category.projects.map((data: any, dataIndex: any) => (
              <Acordeon data={data} key={dataIndex} hours={category} />
            ))}
          </AccordeonSimple>
        ))}
      </BoxLines>
      <HeaderDates>
        <HorasTotais>Total Mês: {decimalParaHoraMinuto(somaHorasTotais)}</HorasTotais>
        <BoxDates>
          {totals.slice(0, currentsWeek.length)?.map((total: any, index: any) => (
            <CellDate key={index}>
              <p>{decimalParaHoraMinuto(total?.total)}</p>
            </CellDate>
          ))}
        </BoxDates>
      </HeaderDates>
    </Container>
  );
}
