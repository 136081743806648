import React, { useEffect, useState } from "react";
import { Container, ContainerFiltros, Title } from "./styles";
import Table from "./partials/table";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { meses } from "@/utils/date";
import { useSheet } from "@/hooks/useSheet";
import { Button, fabClasses } from "@mui/material";
import { LancamentosService } from "@/services/endpoints/lancamentos";
import { useTimes } from "@/hooks/useTime";
import { useSnackbar } from "notistack";

export const ContentHome: React.FC = () => {
  const {
    currentMonth,
    years,
    selectedYear,
    handleSelectMonth,
    handleSelectYear,
  } = useSheet();
  const { postHours } = new LancamentosService();
  const {
    dataToPost,
    setDataToPost,
    disabledButton,
    setDisabledButton,
    handleGetSheetUser,
  } = useTimes();
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = () => {
    postHours(dataToPost)
      .then(() => {
        enqueueSnackbar("Lançamento salvo!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        setDataToPost([]);
        setDisabledButton(false);
        handleGetSheetUser();
      })
      .catch((error) => {

        const isDuplicade = error?.response?.data?.message?.includes(
          "unique_time_coast_category"
        )
        if (!isDuplicade) {

          enqueueSnackbar("Erro ao lançar horas", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }

      });
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (disabledButton) {
        event.preventDefault();
        event.returnValue = "Deseja sair sem salvar as alterações?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [disabledButton]);

  return (
    <Container data-testid="jest_home">
      <Title>Macroplan SACP</Title>
      <ContainerFiltros>
        <div>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">Mes</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={String(currentMonth?.mesNumero)}
              onChange={handleSelectMonth}
              label="Age"
            >
              {meses.map((mes, index) => (
                <MenuItem key={index} value={mes.numero}>
                  {mes.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label"></InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedYear}
              onChange={handleSelectYear}
              label="Age"
            >
              {years?.map((mes: any, index: number) => (
                <MenuItem key={index} value={mes}>
                  {mes}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {disabledButton && (
          <Button onClick={handleSave} variant="contained">
            Salvar
          </Button>
        )}
      </ContainerFiltros>
      <Table />
    </Container>
  );
};
