import React, { useState } from 'react'
import { Arrow, Cell, Container, ContainerBody, ContainerHeader, Input, Line, LineCells, NameCell } from './style'


interface Iprops {
  children: any
  title: string
  arrow: boolean
}


export default function AccordeonSimple({ children , title, arrow}: Iprops) {

  const [open, setOpen] = useState(false)

  const handlClick = () => {
    setOpen(!open)
  };

  return (
    <Container>
      <ContainerHeader onClick={handlClick}>
        <NameCell>
       
          <p>{title}</p>
          {arrow &&(
            <Arrow turn={open}>{">"}</Arrow>
          )}
        </NameCell>
      </ContainerHeader>
      <ContainerBody isOpen={(open )}>
         {children}
      </ContainerBody>
    </Container>
  )
}
