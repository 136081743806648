import LoginContent from '@/components/login';
import { PublicLayout } from "@/components/global/Layout/Public";
import { ContentHome } from '@/components/home';
import { PrivateLayout } from '@/components/global/Layout/Private';

export default function Login() {
  return ( 
    <PrivateLayout>
      <ContentHome/>
    </PrivateLayout>
   );
}
