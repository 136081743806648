import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  height: auto;
  background-color: white;
  padding: 10px;
  margin-top: 5% ;
  `;

export const HeaderDates = styled.div`
  width: 100%;
  height: 60px;
  background-color: white;
  border-bottom: 2px solid gray;
  display: flex;
  `

export const BoxLines = styled.div`
  width: 100%;
  height: 100%;
`

export const Teste = styled.div`
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    border-bottom: 0;
`

export const HorasTotais = styled.div`
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    border-bottom: 0;
    color: black;
`
export const BoxDates = styled.div`
      width: 70%;
    height: 100%;
    display: flex;
    border-bottom: 1px solid rgba(0,0,0,0.3);
`

export const CellDate = styled.div`

    width: 18%;
    height: 100%;
    /* background-color: aliceblue; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`