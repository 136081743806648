import styled from "styled-components";

interface IpropsContainerBody{
  isOpen?: boolean
}
interface IpropSContainerHeader{
  hasActions?: boolean
}
interface IpropsNameCell{
  margin?: boolean
}
interface IpropsArrow {
  turn?: boolean
}
export const Container = styled.div`
  width: 100%;
  height: auto;
`

export const ContainerHeader = styled.div<IpropSContainerHeader>`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0,0,0,0.3);
  cursor: pointer;
  background-color: #F7F6F6;

`

export const BoxLines = styled.div`
  width: 100%;
  height: 100%;
`



export const NameCell = styled.div<IpropsNameCell>`
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: ${props => props.margin ? "0 15px" : "0"};
  border-bottom:   ${props => props.margin ? " 1px solid rgba(0,0,0,0.3)" : "0"};
  
  p{
    color: black;
      margin-right: 10px;
    font-weight: 700;
  }
`
export const Line = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
`

export const ContainerBody = styled.div<IpropsContainerBody>`
  display: ${props => props.isOpen ? "flex" : "none"};
  flex-direction: column;
  padding: 0 15px ;

`
export const LineCells = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  border-bottom:  1px solid rgba(0,0,0,0.3);
  
`;

export const Cell = styled.div`
  width: 20%;
  height: 100%;
  background-color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;

`


export const Input = styled.input`
width: 80%;
height: 80%;
`

export const Arrow = styled.p<IpropsArrow>`
  margin-right: 10px;
  transform: ${props => props.turn ? "rotate(90deg)": "0"};
`